<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/quienessomos.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBarEn />
    <div class="container-fluid mt-5">
      <div class="row mb-6">
        <section class="col-lg-6 mb-lg-0 mb-5">
          <h3 class="slc-title mb-4">About Us</h3>
          <hr class="mb-3 slc-hr">
          <p class="mb-3">
            <span class="slc-text-blue font-bold">Servicios Legales Corporativos y de Enlace Riviera Maya, S. C.</span>,
            was founded in 2008, establishing its offices in the city of Tulum, Quintana Roo, providing services of
            advisory, consulting and legal management in corporate and association matters, as well as in real estate
            matters to various clients throughout the Riviera Maya.
          </p>
          <p class="mb-3">
            Since the opening of its offices to the public and to date, the purpose of the firm has been to provide
            quality services and above all, to give certainty to our clients regarding their businesses and investments,
            mainly in the real estate field.
          </p>
          <p class="mb-3">
            Our motto is <span class="slc-text-blue font-bold">"Prevention is better than cure"</span>, since we give
            our clients all the necessary protection elements to prevent contingencies of all kinds, both in the legal,
            tax, accounting, administrative and operational fields, which It allows to act with security in the field of
            business that each client chooses.
          </p>
          <p class="mb-3">
            We are a team of professionals who provide quality, but also warmth, giving our clients the necessary
            confidence to establish permanent business relationships, supporting them in new fields they wish to
            undertake.
          </p>
          <p class="mb-3">
            Through alliances with other specialized firms, we provide advice on accounting, tax, administrative and
            agrarian matters, which allows us to provide a comprehensive service to our clients, also having liaison
            services with notaries in the region for the implementation of the businesses and investments that are
            required in our field of service.
          </p>
          <p class="mb-3">
            Likewise, good command of the English language has allowed us to work with foreign investors who seek
            security in the businesses they are undertaking or about to undertake in Mexico.
          </p>
          <p class="mb-3">
            As of 2022, we have extended our services to Mexico City and the state of Querétaro, where we aim to
            establish a network of clients like the one we have generated in all our years of services in the Riviera
            Maya, continuing with the same quality and warmth in the attention to our clients.
          </p>
        </section>
        <section class="col-lg-6">
          <WidgetServiciosEn />
          <img src="media/logoslctransp.png" alt="Servicios Legales Corporativos" class="img-fluid" />
        </section>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/en/contact-us" tagContact="Contact Us"
      msg="We're at your service" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './../TopParallax.vue';
import MenuBarEn from './MenuBarEn.vue';
import WidgetServiciosEn from './WidgetServiciosEn.vue';
import BottomParallax from './../BottomParallax.vue';
import Footer from './../Footer.vue';
export default {
  name: "AboutUs",
  mounted() {
  },
  components: { TopParallax, MenuBarEn, WidgetServiciosEn, BottomParallax, Footer }
}
</script>


