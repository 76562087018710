<template>
    <div id="parallax-footer" class="parallax" :style="'background-image: url('+imgParallax+');'">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                  <div class="slc-overlay slc-bg-white slc-rounded-20 mt-4">
                    <h4 class="tm-text-primary mb-5">{{ msg }}</h4>
                    <p class="mb-5">
                      <!-- Texto adicional -->
                    </p>
                    <a :href="linkContact" class="btn btn-primary btn-lg">{{ tagContact }}</a>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BottomParallax',
  props: [
    'imgParallax',
    'linkContact',
    'tagContact',
    'msg'
  ]
}
</script>

<style scoped>
  #parallax-footer {
    min-height: 500px;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .btn-lg {
    font-size: 130%;
    font-weight: 700;
    border-radius: 10px;
    padding: 15px 20px;
  }
</style>