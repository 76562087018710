<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/contacto.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBarEn />
    <div class="container-fluid mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <section class="slc-contact-form-box mx-auto mb-7">
            <h3 class="slc-title mb-3 text-center">Contacto</h3>
            <hr class="mb-5 slc-hr slc-hr-s mx-auto">
            <form id="contact-form" action="" method="POST" class="slc-contact-form">
              <div class="form-group">
                <input type="text" name="name" class="form-control rounded-0" placeholder="Name" required="">
              </div>
              <div class="form-group">
                <input type="email" name="email" class="form-control rounded-0" placeholder="Email" required="">
              </div>
              <div class="form-group">
                <textarea rows="8" name="message" class="form-control rounded-0" placeholder="Message"
                  required=""></textarea>
              </div>

              <div class="form-group mb-0">
                <button type="submit" class="btn btn-primary rounded-0 d-block mx-auto">Enviar</button>
              </div>
            </form>
          </section>
        </div> <!-- /.col-md-6 -->
        <div class="col-md-4">
          <section class="slc-address-box mx-auto">
            <h3 class="slc-title mb-3 text-center">Phone Numbers</h3>
            <hr class="mb-5 slc-hr slc-hr-s mx-auto">
            <p class="mb-1 text-center font-w700">Office Phone</p>
            <p class="mb-1 text-center">(52) 442 195 0796</p>
            <p class="mb-1 mt-2 text-center font-w700">Mobile Phone</p>
            <p class="mb-1 text-center slc-address-text">
              <span class="font-w600">Lic. Zaida Magdalena de Anda Álvarez</span><br />(52) 1 984 8018485
            </p>
            <p class="mb-1 text-center slc-address-text">
              <span class="font-w600">Lic. Gabi Maite López Domingo</span><br />(52) 1 984 249 6059
            </p>
            <p class="mb-2 mt-3 text-center font-w700">Email Address</p>
            <p class="mb-1 text-center slc-address-text">
              <a href="mailto:zaidadeanda@slcrivieramaya.com">zaidadeanda@slcrivieramaya.com</a>
            </p>
            <p class="mb-1 text-center slc-address-text">
              <a href="mailto:gabilopez@slcrivieramaya.com">gabilopez@slcrivieramaya.com</a>
            </p>
            <p class="mb-2 text-center font-w700 mt-3">Website</p>
            <p class="mb-1 text-center slc-address-text">
              <a href="http://www.slcrivieramaya.com">www.slcrivieramaya.com</a>
            </p>
          </section>
        </div> <!-- /.col-md-4 -->
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <section class="slc-address-box mx-auto">
            <h3 class="slc-title mb-3 text-center">Ubicación</h3>
            <hr class="mb-5 slc-hr slc-hr-s mx-auto">
            <p class="mb-1 mx-auto text-center font-w700" style="font-size: 110%;">We're at your service</p>
            <p class="mx-auto text-center">
              Avenida Paseo Pirámide del Pueblito No. 16<br />
              Privada Mitla, Casa 19<br />
              Fraccionamiento Pirámides<br />
              Localidad El Pueblito<br />
              Municipio de Corregidora<br />
              76908, Querétaro
            </p>

            <div class="mapouter mb-60 mt-5">
              <div class="gmap_canvas">
                <iframe id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d934.0096384291248!2d-100.4559048!3d20.5456034!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d34f9bc2679ee9%3A0x68694e3f7fbb858b!2sPrivada%20Mitla!5e0!3m2!1ses!2smx!4v1666234833284!5m2!1ses!2smx"
                  width="100%" height="520" style="border:0;" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </section>
        </div>
      </div> <!-- /.row.justify-content-center -->

    </div> <!-- /.container-fluid.mt-5 -->
    <p class="mb-7"></p>
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/en/contact-us" tagContact="Contact Us"
      msg="We're at your service" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './../TopParallax.vue';
import MenuBarEn from './MenuBarEn.vue';
import BottomParallax from './../BottomParallax.vue';
import Footer from './../Footer.vue';
export default {
  name: "ContactUs",
  components: { TopParallax, MenuBarEn, BottomParallax, Footer }
}
</script>


