<template>
  <footer class="container-fluid">
    <div class="row">
      <p class="col-lg-9 col-md-8 mb-5 mb-md-0">
        Copyright &copy; 2022 &bullet; designed by
        <a target="_blank" href="https://carlosloriadiseno.com" class="slc-link-primary">
          <img src="media/logoloriabranding.png" alt="Loria Branding & Design" style="max-height: 50px; width: auto;" />
        </a>
      </p>
      <div class="col-lg-3 col-md-4 text-right">
        <a rel="nofollow" target="_blank" :href="s.href" class="slc-social-link" v-for="s in socialMedia" :key="s.name">
          <i :class="s.icon+' fa-3x slc-social-icon'"></i>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
  import slc_data from './slc_data.json';
  export default {
    name: 'Footer',
    data() {
      return {
        socialMedia: slc_data.slc.sociales
      }
    }
  }
</script>