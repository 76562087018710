<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/home.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBar />
    <div class="container-fluid mt-5">
      <div class="row mb-6">
        <section class="col-lg-6 mb-lg-0 mb-5">
          <h3 class="slc-title mb-4">¿Qui&eacute;nes somos?</h3>
          <hr class="mb-3 slc-hr">
          <p class="mb-3">
            <span class="slc-text-blue font-bold">Servicios Legales Corporativos y de Enlace Riviera Maya, S. C.</span>,
            se constituyó en el 2008, estableciendo sus oficinas en la ciudad de Tulum, Quintana Roo,
            prestando servicios de asesoría, consultoría y gestoría legal en diversos temas corporativos
            y societarios, así como en materia inmobiliaria a diversos clientes en toda la Riviera Maya.
          </p>
          <p class="mb-3">
            Desde la apertura de sus oficinas al público y hasta la fecha, la finalidad de la firma ha
            sido prestar servicios con calidad y sobre todo, dar certeza a nuestros clientes respecto
            de sus negocios e inversiones, principalmente en el ámbito inmobiliario.
          </p>
          <p>
            <a href="#/quienes-somos" class="btn btn-primary slc-btn-xs">Más info</a>
          </p>
          <img src="media/home_foto.jpg" alt="Company Background Image" class="img-fluid tm-mb-3">
        </section>
        <section class="col-lg-6">
          <WidgetServicios />
        </section>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/contacto" tagContact="Contacto" msg="Estamos para servirle" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './TopParallax.vue';
import MenuBar from './MenuBar.vue';
import WidgetServicios from './WidgetServicios.vue';
import BottomParallax from './BottomParallax.vue';
import Footer from './Footer.vue';
export default {
  name: "Home",
  mounted() {
  },
  components: { TopParallax, MenuBar, WidgetServicios, BottomParallax, Footer }
}
</script>


