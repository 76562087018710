<template>
    <div>
        <h3 class="slc-title mb-4">Servicios</h3>
        <hr class="mb-5 slc-hr">
        <div class="slc-strategy-box mb-5" v-for="(serv,index) in slcServicios" :key="index">
            <img :src="serv.image" :alt="serv.title" class="img-fluid slc-strategy-img" />
            <div>
                <h4 class="slc-text-primary">{{ serv.title }}</h4>
                <p class="slc-strategy-text mb-1" v-html="serv.content"></p>
                <p class="mt-0">
                    <a href="#" class="btn btn-primary slc-btn-xs">Más info</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import slc_data from './slc_data.json';
export default {
    data() {
        return {
          slcServicios: slc_data.slc.servicios_widget
        }
    }
}
</script>

<style scope>
.slc-strategy-img {
    max-width: 180px;
    height: auto;
}
ul {
    list-style-type: square;
    padding-left: 25px;
    margin-bottom: 5px;
}
</style>