<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/quienessomos.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBar />
    <div class="container-fluid mt-5">
      <div class="row mb-6">
        <section class="col-lg-6 mb-lg-0 mb-5">
          <h3 class="slc-title mb-4">¿Qui&eacute;nes somos?</h3>
          <hr class="mb-3 slc-hr">
          <p class="mb-3">
            <span class="slc-text-blue font-bold">Servicios Legales Corporativos y de Enlace Riviera Maya, S. C.</span>,
            se constituyó en el 2008, estableciendo sus oficinas en la ciudad de Tulum, Quintana Roo,
            prestando servicios de asesoría, consultoría y gestoría legal en diversos temas corporativos
            y societarios, así como en materia inmobiliaria a diversos clientes en toda la Riviera Maya.
          </p>
          <p class="mb-3">
            Desde la apertura de sus oficinas al público y hasta la fecha, la finalidad de la firma ha
            sido prestar servicios con calidad y sobre todo, dar certeza a nuestros clientes respecto
            de sus negocios e inversiones, principalmente en el ámbito inmobiliario.
          </p>
          <p class="mb-3">
            Nuestro lema es <span class="slc-text-blue font-bold">“Prevención es mejor que curación”</span>,
            toda vez que le damos a nuestros clientes todos los elementos de protección necesarios para
            prevenir contingencias de todo tipo, tanto en el ámbito legal, como fiscal, contable,
            administrativo y operativo, lo que les permite actuar con seguridad en el campo de negocios
            que cada cliente elige.
          </p>
          <p class="mb-3">
            Somos un equipo de profesionales que brindamos, calidad, pero también calidez, dando a
            nuestros clientes la confianza necesaria para establecer relaciones de negocios permanentes,
            apoyándolos en nuevos campos que desean emprender.
          </p>
          <p class="mb-3">
            A través de alianzas con otras firmas especializadas, brindamos asesoría en cuestiones contables,
            fiscales, administrativas y agrarias, lo que nos permite dar un servicio integral a nuestros
            clientes, contando además con servicios de enlace con notarías y corredurías de la región para
            la implementación de los negocios e inversiones que se requieren en nuestro campo de servicio.
          </p>
          <p class="mb-3">
            Asimismo, el buen manejo del idioma inglés nos ha permitido trabajar con inversionistas
            extranjeros que buscan seguridad en los negocios que están emprendiendo o por emprender
            en México.
          </p>
          <p class="mb-3">
            A partir de 2022, hemos extendido nuestros servicios a la Ciudad de México y al estado
            de Querétaro, donde tenemos como meta establecer una red de clientes como la que hemos
            generado en todos nuestros años de servicios en la Riviera Maya, continuando con la misma
            calidad y calidez en la atención a nuestros clientes.
          </p>
        </section>
        <section class="col-lg-6">
          <WidgetServicios />
          <img src="media/logoslctransp.png" alt="Servicios Legales Corporativos" class="img-fluid" />
        </section>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/contacto" tagContact="Contacto" msg="Estamos para servirle" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './TopParallax.vue';
import MenuBar from './MenuBar.vue';
import WidgetServicios from './WidgetServicios.vue';
import BottomParallax from './BottomParallax.vue';
import Footer from './Footer.vue';
export default {
  name: "QuienesSomos",
  mounted() {
  },
  components: { TopParallax, MenuBar, WidgetServicios, BottomParallax, Footer }
}
</script>


