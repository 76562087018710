<template>
  <div id="app">
    <component :is="currentView" />
  </div>
</template>

<script>
import Home from './components/Home.vue'
import QuienesSomos from './components/QuienesSomos.vue'
import Servicios from './components/Servicios.vue'
import Socios from './components/Socios.vue'
import Contacto from './components/Contacto.vue'
import NotFound from './components/NotFound.vue'
// English:
import HomeEn from './components/en/HomeEn.vue'
import AboutUs from './components/en/AboutUs.vue'
import Services from './components/en/Services.vue'
import Partners from './components/en/Partners.vue'
import ContactUs from './components/en/ContactUs.vue'


const routes = {
  '/': Home,
  '/quienes-somos': QuienesSomos,
  '/servicios': Servicios,
  '/socios': Socios,
  '/contacto': Contacto,
  // English:
  '/en': HomeEn,
  '/en/about-us': AboutUs,
  '/en/services': Services,
  '/en/partners': Partners,
  '/en/contact-us': ContactUs
}

export default {
  name: 'App',
  components: {
    Home, QuienesSomos, Servicios, Socios, Contacto,
    HomeEn, AboutUs, Services, Partners, ContactUs
  },
  data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || NotFound
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
		});
  }
}
</script>