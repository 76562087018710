<template>
    <div id="parallax-header" class="parallax" :style="'background-image: url('+imgParallax+');'">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="slc-logo">
                        <img :src="logoImage" :alt="logoTitle" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TopParallax',
  props: [
    'imgParallax',
    'logoImage',
    'logoTitle'
  ],
  mounted() {
   console.log('top parallax loaded')
  }
}
</script>

<style>
  #parallax-header {
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>