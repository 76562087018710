<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/quienessomos.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBarEn />
    <div class="container-fluid mt-5">
      <div class="row mb-6">
        <section class="col-lg-8 mb-lg-0 mb-5">
          <h3 class="slc-title mb-4">Socios</h3>
          <hr class="mb-3 slc-hr">
          <div class="slc-strategy-box mb-5" v-for="(soc,index) in socios" :key="index">
            <img :src="soc.image" :alt="soc.name" class="img-fluid slc-strategy-img" />
            <div>
              <h4 class="slc-text-primary">{{ soc.name }}</h4>
              <div v-html="soc.content"></div>
            </div>
          </div>
        </section>
        <section class="col-lg-4">
          <WidgetServiciosEn />
        </section>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/en/contact-us" tagContact="Contact Us"
      msg="We're at your service" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './../TopParallax.vue';
import MenuBarEn from './MenuBarEn.vue';
import WidgetServiciosEn from './WidgetServiciosEn.vue';
import BottomParallax from './../BottomParallax.vue';
import Footer from './../Footer.vue';
import slc_data_en from './slc_data_en.json';
export default {
  name: "Partners",
  mounted() {
  },
  data() {
    return {
      socios: slc_data_en.slc.socios
    }
  },
  components: { TopParallax, MenuBarEn, WidgetServiciosEn, BottomParallax, Footer }
}
</script>


