<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/home.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBarEn />
    <div class="container-fluid mt-5">
      <div class="row mb-6">
        <section class="col-lg-6 mb-lg-0 mb-5">
          <h3 class="slc-title mb-4">About Us</h3>
          <hr class="mb-3 slc-hr">
          <p class="mb-3">
            <span class="slc-text-blue font-bold">Servicios Legales Corporativos y de Enlace Riviera Maya, S. C.</span>,
            was founded in 2008, establishing its offices in the city of Tulum, Quintana Roo, providing services of
            advisory, consulting and legal management in corporate and association matters, as well as in real estate
            matters to various clients throughout the Riviera Maya.
          </p>
          <p class="mb-3">
            Since the opening of its offices to the public and to date, the purpose of the firm has been to provide
            quality services and above all, to give certainty to our clients regarding their businesses and investments,
            mainly in the real estate field.
          </p>
          <p>
            <a href="#/en/about-us" class="btn btn-primary slc-btn-xs">More info</a>
          </p>
          <img src="media/home_foto.jpg" alt="Company Background Image" class="img-fluid tm-mb-3">
        </section>
        <section class="col-lg-6">
          <WidgetServiciosEn />
        </section>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/en/contact-us" tagContact="Contact Us" msg="We're at your service" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './../TopParallax.vue';
import MenuBarEn from './MenuBarEn.vue';
import WidgetServiciosEn from './WidgetServiciosEn.vue';
import BottomParallax from './../BottomParallax.vue';
import Footer from './../Footer.vue';
export default {
  name: "HomeEn",
  mounted() {
  },
  components: { TopParallax, MenuBarEn, WidgetServiciosEn, BottomParallax, Footer }
}
</script>


