<template>
    <div class="slc-nav-container-outer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <nav class="navbar navbar-expand-lg" id="slc-main-nav">
                        <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbar-nav" 
                            aria-controls="navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="dark-blue-text"><i class="fas fa-bars"></i></span>
                        </button>
                        <div class="collapse navbar-collapse slc-nav" id="navbar-nav">
                            <ul class="navbar-nav ml-auto">
                                <li :class="'nav-item '+(currentView == ml.link ? 'active' : '')" v-for="(ml,idx) in menuLinks" :key="idx">
                                    <a class="nav-link slc-nav-link" :href="'#/'+ml.link">
                                        {{ ml.label }} <span class="sr-only" v-if="(currentView == ml.link)">(current)</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link slc-nav-link">
                                        <img src="media/flag_es.png" alt="Español" style="width: 40px; height: auto;" />
                                    </a>
                                </li>
                            </ul>                            
                        </div>                        
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import slc_data_en from './slc_data_en.json';
export default {
    data() {
        return {
            currentPath: window.location.hash,
            menuLinks: slc_data_en.slc.menu
        }
    },
    computed: {
        currentView() {
            return this.currentPath.slice(2) || ''
        }
    },
    mounted() {
        window.addEventListener('hashchange', () => {
            this.currentPath = window.location.hash
        });
    }
}
</script>