<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/servicios.jpg" logoImage="media/logoslcwhite.png"
      logoTitle="Servicios Legales Corportativos" />
    <MenuBar />
    <div class="container-fluid mt-5">
      <div class="row mb-6 justify-content-center">
        <section class="col-lg-8 mb-lg-0 mb-5">
          <h3 class="slc-title mb-4">Servicios</h3>
          <hr class="mb-3 slc-hr">
          <div class="slc-strategy-box mb-5" v-for="(serv,index) in slcServicios" :key="index">
            <img :src="serv.image" :alt="serv.title" class="img-fluid slc-strategy-img" />
            <div>
              <h4 class="slc-text-primary">{{ serv.title }}</h4>
              <ul>
                <li v-for="(item,idx) in serv.lista" :key="idx" v-html="item"></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <BottomParallax imgParallax="media/parallax/footer.jpg" linkContact="#/contacto" tagContact="Contacto" msg="Estamos para servirle" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './TopParallax.vue';
import MenuBar from './MenuBar.vue';
import BottomParallax from './BottomParallax.vue';
import Footer from './Footer.vue';
import slc_data from './slc_data.json';
export default {
  name: "Servicios",
  data() {
    return {
      slcServicios: slc_data.slc.servicios_full
    }
  },
  components: { TopParallax, MenuBar, BottomParallax, Footer }
}
</script>


