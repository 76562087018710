<template>
  <div class="slc-page-container">
    <TopParallax imgParallax="media/parallax/home.jpg" logoImage="media/logoslcwhite.png" logoTitle="Servicios Legales Corportativos" />
    <MenuBar />
    <div style="height:100px !important;"></div>
    <div class="container-fluid mt-5">
      <div class="row mb-6">
        <div class="col-md-4 offset-md-2">
          <h1 class="text-center display-1 slc-text-blue font-bold">
            404
          </h1>
        </div>
        <div class="col-md-6">
          <p class="mb-3 h2 mt-4">Página no encontrada.</p>
        </div>
      </div>
    </div> <!-- /.container-fluid.mt-5 -->
    <div style="height:200px !important;"></div>
    <BottomParallax imgParallax="media/parallax/footer.jpg" />
    <Footer />
  </div>
</template>

<script>
import TopParallax from './TopParallax.vue';
import MenuBar from './MenuBar.vue';
import BottomParallax from './BottomParallax.vue';
import Footer from './Footer.vue';
export default {
    name: "NotFound",
    mounted() { 
    },
    components: { TopParallax, MenuBar, BottomParallax, Footer }
}
</script>


